import packageInfo from '../../package.json';

export const environment = {
  production: true,
  apiUrl: 'https://doutdes.izs.it/api-doutdes/api',
  appVersion:  packageInfo.version,
  mailSupport: 'doutdes.support@izs.it',
  siteKey: '6LdJWoccAAAAAJkRMAy5fz4qxU1AY0EqWcl-U8Ny',
  appName: 'DOUTDES - Mapping Tool'
};
